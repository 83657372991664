const SERVER_URL = 'http://localhost:3000'

export class Api {
  headers: any[]
  baseUrl: string

  constructor({instance, baseUrl = SERVER_URL, XSRFTOKEN = ''}) {
    this.headers = [
      ['Authorization', instance],
      ['Content-Type', 'application/json'],
      ['X-XSRF-TOKEN', XSRFTOKEN]
    ]
    this.baseUrl = baseUrl
  }

  getAppData = async () => {
    return fetch(`${this.baseUrl}/app-data`, {
      method: 'GET',
      headers: this.headers,
    }).then(response => response.json())
  }
}
