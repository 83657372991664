export const withErrorHandler = (f: Function) => {
  console.log('Ev-Widget: Error handler')
  return (...args: any[]) => {
    try {
      return f(...args)
    } catch (e) {
      console.error(e.stack)
      throw e
    }
  }
}
