const defaultState: any[] = []

export const events = (state = defaultState, action: any) => {
  switch (action.type) {
    default:
      return state
  }
}
export const getEvents = (state: any): any => {
  return state.events
}
