console.log('Ev-Widget: viewer.ts')

import {createWidgetController} from './controllers/'
import {withErrorHandler} from './utils/error-handler'

const EVENTS_WIDGET_ID = '3abb0616-59fe-492a-af9c-c37b831d92dc'

export const createControllers = withErrorHandler((controllerConfigs: any) =>
  controllerConfigs.map((c: any) => {
    return Promise.resolve({
      pageReady: async ($w: any, api: any) => {
        console.log('Ev-Widget: Page ready')
        if (c.type === EVENTS_WIDGET_ID) {
          await createWidgetController(c)
        }
      }
    })
  }))
