import {IWidgetControllerConfig} from 'native-components-infra/dist/src/types/types'
import reducers from '../reducers'
import {Api} from '../utils/api'
import {createReduxStore} from '../utils/store'

export const createWidgetController = async (c: IWidgetControllerConfig) => {
  console.log('Ev-Widget: creating Widget controller', c)
  const serverApi = new Api({
    baseUrl: c.appParams.baseUrls.eventsServerBaseUrl,
    instance: c.appParams.instance
  })

  const initialData = await serverApi.getAppData()

  const store = createReduxStore({serverApi, reducers, initialData, wixCodeApi: c.wixCodeApi})

  console.log('Ev-Widget: ', store.getState())

  const props = {
    cssBaseUrl: c.appParams.baseUrls.staticsBaseUrl,
    setHeight: ({height}: any) => {
      c.setProps({height})
    }
  }
  c.setProps(props)
}
